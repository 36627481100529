<template>
  <div>
    <div class="text-center border-dash form-title">
      <h4 class="form-red-title">
        <span class="mr-2">{{examInfo.ksmc}}</span>
      </h4>
      <h3 class="sub-header">网上报名信息提交</h3>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="from-header-bg border-dash">
          <div class="card-body">
            <div class="card-title">
              <div>
                1、标注为“*”号的项目为必填项，照片请必须使用标准照，注意使用照片审核工具审核；
              </div>
              <div class="mt-1">
                <span class="mr-3"
                  >2、报名时间：{{examInfo.bmkssj}} 至 {{examInfo.bmjssj}}
                </span>
                <span>缴费时间：{{examInfo.jfkssj}} 至 {{examInfo.jfjssj}} </span>
              </div>
            </div>
            <!-- <div class="flexList from-header">
              <div class="d-flex w-22 :class="{ active: pageNum > 0 }"">
                <div class="data-tips flexList">
                  <div class="form-num">
                    <div class="form-circle mr-2">
                      <span class="circle-font">1</span>
                    </div>
                  </div>
                  <div class="tip-font">
                    <div class="flexList">
                      <div class="fw600 tip-top">提交报名</div>
                      <div class="form-line"></div>
                    </div>
                    <div class="form-desc-box mt-1">
                      按要求提交完成所有数据并检查确认
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex w-22" :class="{active:pageNum > 1}">
                <div class="data-tips flexList">
                  <div class="form-num">
                    <div class="form-circle mr-2">
                      <span class="circle-font">2</span>
                    </div>
                  </div>
                  <div class="tip-font">
                    <div class="flexList">
                      <div class="fw600 tip-top">资格审核</div>
                      <div class="form-line"></div>
                    </div>
                    <div class="form-desc-box mt-1">
                      等待审核人员审核
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex w-22" :class="{active:pageNum > 2}">
                <div class="data-tips flexList">
                  <div class="form-num">
                    <div class="form-circle mr-2">
                      <span class="circle-font">3</span>
                    </div>
                  </div>
                  <div class="tip-font">
                    <div class="flexList">
                      <div class="fw600 tip-top">
                        在线缴费
                      </div>
                      <div class="form-line"></div>
                    </div>
                    <div class="form-desc-box mt-1">
                      审核成功后请尽快缴费，避免延误考试
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex w-22" :class="{active:pageNum > 3}">
                <div class="data-tips flexList">
                  <div class="form-num">
                    <div class="form-circle mr-2">
                      <span class="circle-font">4</span>
                    </div>
                  </div>
                  <div class="tip-font">
                    <div class="flexList">
                      <div class="fw600 tip-top">完成报名</div>
                      <div class="form-line"></div>
                    </div>
                    <div class="form-desc-box mt-1">
                      缴费完成，完成报名
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex w-22" :class="{active:pageNum > 4}">
                <div class="data-tips flexList">
                  <div class="form-num">
                    <div class="form-circle mr-2">
                      <span class="circle-font">5</span>
                    </div>
                  </div>
                  <div class="tip-font">
                    <div class="flexList">
                      <div class="fw600 tip-top">
                        打印准考证、考试
                      </div>
                    </div>
                    <div class="form-desc-box mt-1">
                      请尽快在考试前打印准考证，参加考试
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: {
    pageNum: {
      type: Number,
      default: 3,
    },
    examInfo:{
      typeof:Object,
    }
  },
  data() {
    return {};
  },
  mounted() {
    // let list = $(".from-header").children();

    // for (let index = 0; index < this.pageNum; index++) {
    //   $(list[index]).addClass('from-header-active')
      
    // }
  },
};
</script>

<style>
.from-header.flexList{
  /* justify-content: space-between; */
}
.tip-font .flexList{
  width: 100%;
}
.active .form-circle {
  background-color: #1990FF;
  border-color: #2F8CE6;
  color: #FFFFFF;
}

.active .form-line {
  border-color: #1990FF;
}
.card-title{
  display: block;
}
</style>
